.wrapper {
  margin: 10px;
  display: block;
}

.imageContainer {
  position: relative;
  display: flex;
  justify-content: center;
}
.navigator {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  margin-top: 10px;
}
.button {
  background-color: transparent;
  position: relative;
  opacity: 55%;
  border: none;
  transition: all 200ms;

  display: flex;
  align-items: center;
}

.button:hover {
  color: #00ADB5;
  opacity: 75%;
}

.leftButton {
  padding-right: 1rem;
  padding-left: 1rem;
}

.rightButton {
  padding-right: 1rem;
  padding-left: 1rem;
}

@media only screen and (max-width: 600px) {
  .button {
    font-size: 30px;
  }
}
@media only screen and (min-width: 600px) {
  .button {
    font-size: 30px;
  }
}


img {
  aspect-ratio: auto;
  max-height: 500px;
  max-width: 90%;
  object-fit: scale-down;
  height: auto;
  width: auto;
  border-radius:  5px;
  box-shadow: 0px 0px 5px 0px teal;
  /* box-shadow: 0px 0px 15px #A1CCD1; */
}

img::before {
  display: flex;
  justify-content: center;
  content: "Image broken / Missing";
  padding-top: 45%;
  padding-bottom: 0px;
  display: block;
  margin-bottom: 10px;

  height: 100%;
  width: 100%
}