.displayItem {
  display: flex;
  flex-direction: row;
  padding: 2rem;
  padding-bottom: 4rem;
  padding-top: 4rem;
}

.flex {
  display: flex;
}

.informationContent {
  flex-basis:60%;
}

.imageContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis:40%;
}

.technologyItems {
  color: #00ADB5;
  text-align: left;
  font-weight: lighter;
}

@media only screen and (max-width: 600px) {
  .icon {
    font-size: 30px;
  }
}
@media only screen and (min-width: 600px) {
  .icon {
    font-size: 50px;
  }
}
.icon {
  display: flex;
  justify-content: left;
}

.linkIcons {
  margin-right: 1.5rem;
  transition: color 500ms;
  color: #00565a;
}
.linkIcons:hover {
  color: lightblue;
}



.slideFromRightBox {
  animation: slideFromRight 1.2s ease-out;

}

.slideFromLeftBox {
  animation: slideFromLeft 1.2s ease-out ;

}

.delaySlideFromRightBox {
  animation: slideFromRight 1.7s ease-out;

}

.delaySlideFromLeftBox {
  animation: slideFromLeft 1.7s ease-out ;

}

.imageSlideLeft {
  animation: slideFromLeft 1.7s ease-in-out;
}
.imageSlideRight {
  animation: slideFromLeft 1.7s ease-in-out;
}

@keyframes slideFromRight {
  from {transform: translateX(10%);
        opacity: 25%;}

  to {transform: translateX(0%);
  opacity: 100%;
}

}

@keyframes slideFromLeft {
  from {transform: translateX(-10%);
    opacity: 25%;}

  to {transform: translateX(0%);
    opacity: 100%;}

}

@media screen and (max-width: 700px) {
  .displayItem {
    flex-direction: column;
  }
}