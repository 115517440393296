.contentBox {
  margin-top: calc(1rem + 1vh);
}
/* .image {
  max-height: 250px;
  max-width: 150px;
  border-radius: 10%;
  margin: 0rem 2rem 2rem 2rem;
} */

.aboutWrapper {
  display: block;
  text-align: left;
  margin: 2rem;
  padding: 3rem 5vw 3rem 5vw;
}

.flex {
  display: flex;
}

.socialLinks {
  transition: all;
  transition-duration: 300ms;
  color: #00565a;
  font-size: 50px;
}
.socialLinks:hover {
  color: lightblue;
}



@media only screen and (max-width: 600px) {
  .socialLinks {
    font-size: 30px;
  }
}