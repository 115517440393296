.largeTitle {
  margin: 0px;
  font-size: calc(1.5rem + 1.5vw);
}
.subTitle {
  margin: 0px;
  color: darkgray;
  font-weight: lighter;
  filter: brightness(80%);
  font-size: calc(1rem + 0.75vw);
  
}
.highlight { 
  color: #00ADB5;
  filter: brightness(100%);
}

.header {
  font-size: calc(1.5rem + 0.5vw);
}

.text {
  font-size: calc(1rem + 0.5vw);
}