.name {
  display: flex;
  margin: auto;
  justify-content: space-between;
  font-size: calc(0.75rem + 1vw);

}
.rightItems {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-right: 2rem;
}

.header {
  transition: background-color 200ms;
  font-size: calc(0.5rem + 1vw);
  padding: 2rem;
  background-color: transparent;
  border: none;
}
.header:hover {
  background-color: #00ADB5;
}
.header:active{
  -webkit-filter: brightness(75%); 
}
.leftItems {
  margin-left: 2rem;
} 

.border {
  width: 1px;
  background-color: teal;
  border: 0px;
  height: 10vh;
}