.container {
  display: flex;
  flex-direction: column;

  text-align: left;
  max-width: 50vw;
  margin: auto;
  padding: 3rem 5vw 3rem 5vw;
}

.title {
  font-size: calc(15px + 1.5vw);
}

.button {
  font-size: 20px;
  color: white;
  background-color: #00ADB5;
  border-radius: 5%;
  align-self: center;
  transition: background-color 300ms;
}
.button:hover {
  background-color: lightblue;;
}
.icon {
  display: flex;
  transition: color 300ms;
  color: #00ADB5;
  align-items: center;
}
.icon:hover {
  color: lightblue;;
}

@media only screen and (max-width: 600px) {
  .icon {
    font-size: 30px;
  }
  .button {
    font-size: 10px;
    padding: 0.5rem 1rem 0.5rem 1rem;
  }
  .contactBox {
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media only screen and (min-width: 600px) {
  .icon {
    font-size: 50px;
  }
  .button {
    font-size: 30px;
  }
  .contactBox {
    padding: 2rem;
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.contactBox {
  justify-content: space-between;
  display: flex;
  border: dashed blue 1px;
}