.displayBox {
  display: block;
  margin: 1rem 5vw 0rem 5vw;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-radius: 1%;
}
.children {
  margin: auto;
  display: flex;
  flex-flow: column wrap;

}
.header { 
  font-size: calc(15px + 1.5vw);
  padding: 2rem;
  padding-bottom: 4rem;
}