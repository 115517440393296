.container {
  display: flex;
  padding: 0px;
  margin: 0px;
  height: 30px;
}

.slideUp {
  animation: 750ms slideFadeUp;
  transition: all 500ms;
  opacity: 0;
  animation-fill-mode: forwards;
}

@keyframes slideFadeUp {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}