.App {
  height: fit-content;
  font-size: 2rem;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
body {
  background-color: #EEEEEE;
}

.footer {
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 0px;
  /* background-color: ; */
  font-size: calc(0.5rem + 1vw);
}

.slideOutItem {
  animation: slideLeft 1s;
  position: absolute;
  animation-fill-mode: forwards;
}
.slideInDownItem {
  animation: slideInDown 1.5s;
  position: absolute;
  animation-fill-mode: forwards;
}

.slideUpDelayedItem {
  opacity: 0%;
  position: absolute;
  animation: slideUp 1.5s;
  animation-fill-mode: forwards;
}
.slideDownDelayedItem {
  animation: slideDown 1.5s;
  animation-fill-mode: forwards;
}

@keyframes slideInDown {
  from {
    opacity: 0%;
    display: none;
    -webkit-transform: translateY(-100%);
  }
  to {
    opacity: 100%;
    transform: none;
    display: block;
  }
}

@keyframes slideLeft {
  from {transform: none;}

  to {opacity: 0%;
      display: none;
      -webkit-transform: translateX(-100%);
    }
}

@keyframes slideUp {
  from {
    opacity: 0%;

    -webkit-transform: translateY(100%);
  }
  to {
    opacity: 100%;
    transform: none;
    display: block;
  }
}
@keyframes slideDown {
  from {
    opacity: 100%;
    transform: none;
    position: fixed;
  }
  to {
    opacity: 0%;
    -webkit-transform: translateY(100%);
    position: fixed;
    display: none;
  }
}

.fadeIn 
{
  animation: fade 1s;
}
@keyframes fade {
  0% {
    opacity: 0%;
  }
  90% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}